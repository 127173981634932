import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Toaster } from 'react-hot-toast';

import store from './store/store';
import history from './history'
import Login from './auth/Login';
import Privacy from './auth/Privacy';
import { EmailVerificationSuccess, EmailVerificationFail } from './auth/EmailVerification';
import App from './App';
import LoginRequiredRoute from './LoginRequiredRoute';


class AppContainer extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route path="/login/" component={Login} />
                        <Route path="/privacy/" component={Privacy} />
                        <Route path="/email-verification-success/" component={EmailVerificationSuccess} />
                        <Route path="/email-verification-fail/" component={EmailVerificationFail} />
                        <LoginRequiredRoute path="/" component={App} />
                    </Switch>
                    <Toaster
                        position="bottom-right"
                        reverseOrder={false}
                        toastOptions={{
                            style: {
                                padding: '2rem',
                                border: '1px solid #bbb'
                            },
                            duration: 5000,
                        }}
                    />
                    <ReactTooltip effect="solid" html={true} />
                </Router>
            </Provider>
        );
    }
}

export default AppContainer;