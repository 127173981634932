import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Helmet from '../helmet';
import request from '../api-request';
import { TextField } from '../components/forms';
import Error from '../components/Error';
import { setAuthToken } from './actions';
import logo from '../img/logo_dark_wide.png';
import history from '../history';
import { GENERIC_ERROR_MSG } from '../constants';
import OTPForm from '../auth/OTPForm';
import Modal from '../components/Modal';
import '../scss/Login.scss';


class Login extends React.Component {
    state = {
        username: '',
        password: '',
        loginLoading: false,
        loginErrors: null,
        OTPLoginLoading: false,
        OTPLoginErrors: null,
        otp: '',
        key: 0
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.otp?.length !== 6 && this.state.otp.length === 6) {
            this.loginOTP();
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({ [name]: value });
    }

    actualLogin = (res) => {
        const { token } = res.data;

        // We could redirect to this.props.location.state.redirectTo
        // but first check if it's /login/
        const redirectTo = '/';

        this.props.setAuthToken(token);
        localStorage.setItem('authToken', token);
        history.push(redirectTo);
    }

    loginOTP = () => {
        const { username, password, otp } = this.state;
        this.setState({
            OTPLoginLoading: true,
            OTPLoginErrors: null,
            otp: '',
            key: this.state.key + 1
        });

        request.post('auth/login/', { otp }, {
            auth: { username, password }
        })
            .then(res => {
                this.actualLogin(res);
                this.setState({ OTPLoginLoading: false, otp: '' });
            })
            .catch(error => {
                let err;
                if (error.response?.data?.reason === 'N_FAILED_ATTEMPTS') {
                    err = { detail: `You have entered an invalid code too many times. Please try again later`}
                } else {
                    err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_MSG };
                }
                this.setState({ OTPLoginLoading: false, OTPLoginErrors: err, otp: '' });
            });
    }

    login = (e) => {
        e.preventDefault();
        this.setState({ loginLoading: true, loginErrors: null });

        const { username, password } = this.state;
        request.post('auth/login/', null, {
            auth: { username, password }
        })
            .then(res => {
                if (res.data.detail === 'OTP required') {
                    this.setState({
                        modalOpen: true,
                        loginLoading: false,
                        otp: '',
                        OTPLoginErrors: null
                    });
                } else {
                    this.actualLogin(res);
                }
            })
            .catch(error => {
                const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_MSG };
                this.setState({ loginLoading: false, loginErrors: err });
            });
    }

    closeOTPModal = () => {
        this.setState({
            modalOpen: false,
            username: '',
            password: ''
        });
    }

    render() {
        return (
            <div className="centered-hv">
                <Helmet>
                    <title>Login</title>
                </Helmet>
                <img style={{ display: 'block', width: '200px', marginBottom: '1rem' }} src={logo} alt="Block.co"/>
                <div className="card">
                    <div className="card-body">
                        <h1 className="my-heading text-center" style={{ marginBottom: '1rem' }}>
                            Sign in to Block.co
                        </h1>
                        <form onSubmit={this.login}>
                            <Error errorObj={this.state.loginErrors} prop="detail" />
                            <TextField
                                id="username"
                                label="Username"
                                name="username"
                                value={this.state.username}
                                required={true}
                                disabled={this.state.loginLoading}
                                onChange={this.handleChange}
                                autoFocus
                            />
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                name="password"
                                value={this.state.password}
                                required={true}
                                disabled={this.state.loginLoading}
                                onChange={this.handleChange}
                            />
                            <button id="login_button" className="btn btn-primary btn-block" type="submit" disabled={this.state.loginLoading}>Sign in</button>
                        </form>
                        <p className="text-muted my-2">
                            Don't have an account? <a href="mailto:enquiries@block.co">Contact us</a>
                        </p>
                    </div>
                </div>
                <div className="mt-2 d-flex px-1" style={{ justifyContent: 'space-between', width: '250px', fontSize: '0.85rem' }}>
                    <div><Link to="/privacy/" className="text-muted">Privacy policy</Link></div>
                    <div><a href="mailto:enquiries@block.co" className="text-muted">Contact</a></div>
                </div>

                <Modal
                    isOpen={this.state.modalOpen}
                    closeModal={this.closeOTPModal}
                    title="Enter your 2FA code"
                    body={
                        <OTPForm
                            key={this.state.key}
                            setOTP={(otp) => this.setState({ otp })}
                            loading={this.state.OTPLoginLoading}
                            error={this.state.OTPLoginErrors}
                        />
                    }
                />
            </div>
        );
    }
}

export default connect(
    null,
    { setAuthToken }
)(Login);