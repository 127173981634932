import { createReducer } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import {
    setUserData,
    clearUserData,
    setClientLogo,
    setDomainIdentificationStarted,
    setDomainIdentificationFailed,
    setDomainIdentification,
    setAuthToken,
    statsStarted,
    statsFailed,
    setStats
} from './actions';


const userInitialState = {
    authToken: localStorage.getItem('authToken'),
    userData: null
};

const userReducer = createReducer(userInitialState, {
    [setAuthToken]: (state, action) => {
        state.authToken = action.payload;
    },
    [setUserData]: (state, action) => {
        state.userData = action.payload;
    },
    [clearUserData]: (state, action) => {
        state.authToken = null;
        state.userData = null;
    },
    [setClientLogo]: (state, action) => {
        console.log('payload', action.payload);
        state.userData.client.logo = action.payload;
    },
    [setDomainIdentificationStarted]: (state, action) => {
        state.userData.client.domainIdentificationErrors = null;
        state.userData.client.domainIdentificationLoading = true;
    },
    [setDomainIdentificationFailed]: (state, action) => {
        state.userData.client.domainIdentificationErrors = action.payload;
        state.userData.client.domainIdentificationLoading = false;
    },
    [setDomainIdentification]: (state, action) => {
        state.userData.client.domain_identity_proof = action.payload;
        state.userData.client.domainIdentificationLoading = false;
    }
});


const statsInitialState = {
    loading: false,
    errors: null
};

const statsReducer = createReducer(statsInitialState, {
    [statsStarted]: (state, action) => {
        state.errors = null;
        state.loading = true;
    },
    [statsFailed]: (state, action) => {
        state.loading = false;
        state.errors = action.payload;
    },
    [setStats]: (state, action) => {
        state.loading = false;
        state.totals = action.payload;
    }
});

const authReducer = combineReducers({
    user: userReducer,
    stats: statsReducer
});

export default authReducer;