import React from 'react';
import { Helmet as DefaultHelmet } from "react-helmet";

const Helmet = ({children}) => (
    <DefaultHelmet
        defaultTitle="Block.co"
        titleTemplate="%s | Block.co"
    >
        {children}
    </DefaultHelmet>
);

export default Helmet;