import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';


const LoginRequiredRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            rest.authToken ?
                <Component {...props} /> :
                <Redirect
                    to={{
                        pathname: '/login/',
                        state: { redirectTo: props.location.pathname }
                    }}
                />
        )}
    />
)

export default connect(
    state => ({
        authToken: state.auth.user.authToken,
    })
)(LoginRequiredRoute);