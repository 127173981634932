/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { Link } from 'react-router-dom';

import config from './config';
import request from './api-request';
import Loader from './components/Loader';
import ActionEntry from './components/ActionEntry';
import Error from './components/Error';
import Dropdown from './components/Dropdown';
import { GENERIC_ERROR_LOAD_MSG } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';


class Notifications extends React.Component {
    refreshTimeout = null;
    state = {
        loading: true,
        errors: null,
        actionLog: [],
        unreadCount: 0,
        lastFetched: 0
    }

    componentDidMount() {
        this.getNotifications();
    }

    componentWillUnmount() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    getNotifications = () => {
        this.setState({ loading: true, errors: null });

        request.get('/notifications/')
            .then(res => {
                const actionLog = res.data.action_log;
                const lastSeen = res.data.last_seen;
                let lastFetched = this.state.lastFetched;
                let unreadCount = 0;
                if (actionLog.length > 0) {
                    lastFetched = actionLog[0].id;
                }
                for (const action of actionLog) {
                    if (action.id > lastSeen) {
                        action.unread = true;
                        unreadCount += 1;
                    }
                }
                this.setState({ loading: false, actionLog, unreadCount, lastFetched });
                this.refreshTimeout = setTimeout(this.refreshNotifications, config.REFRESH_NOTIFICATIONS_INTERVAL);
            })
            .catch(error => {
                const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_LOAD_MSG };
                this.setState({ loading: false, errors: err });
            })
    }

    refreshNotifications = () => {
        request.get(`/notifications/?after=${this.state.lastFetched}`)
            .then(res => {
                if (res.data.length > 0) {
                    let { actionLog, unreadCount, lastFetched } = this.state;
                    const newActionLog = res.data.map(a => { a.unread = true; return a; });
                    if (newActionLog.length > 0) {
                        lastFetched = newActionLog[0].id;
                    }
                    unreadCount += newActionLog.length;
                    actionLog.unshift(...newActionLog);
                    this.setState({ actionLog, unreadCount, lastFetched });
                }
                this.refreshTimeout = setTimeout(this.refreshNotifications, config.REFRESH_NOTIFICATIONS_INTERVAL);
            })
            .catch(error => {
                const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_LOAD_MSG };
                console.error('Could not refresh notifications', err);
            })
    }

    onClose = () => {
        const { actionLog } = this.state;
        for (const action of actionLog) {
            action.unread = false;
        }
        this.setState({ actionLog });
    }

    onOpen = () => {
        let changed = false;
        let lastId = 0;
        let { actionLog, unreadCount } = this.state;
        for (const action of actionLog) {
            if (action.unread) {
                changed = true;
                // If there was no last id, update it, else leave it since the
                // first one will be the highest. Also set unreadCount to 0 (only once)
                if (lastId === 0) {
                    lastId = action.id;
                    unreadCount = 0;
                }
            }
        }
        this.setState({ unreadCount });

        if (changed) {
            // If there was any change, update it in the backend
            request.post(`/notifications/${lastId}/`)
        }
    }

    render() {
        const { loading, errors, actionLog, unreadCount } = this.state;

        return (
            <Dropdown
                component="div"
                buttonClassName="btn btn-link nav-link"
                menuClassName="notifications-dropdown p-0 mt-3"
                includeCaret={false}
                onOpen={this.onOpen}
                onClose={this.onClose}
                button={
                    <div className="position-relative">
                        <FontAwesomeIcon icon={faBell} size="lg" onClick={this.toggleNotifications} />
                        {unreadCount > 0 && <div className="badge badge-danger notifications-badge">{unreadCount}</div>}
                    </div>
                }
            >
                {loading ?
                    <Loader /> :
                    <div>
                        <Error errorObj={errors} prop="detail" />
                        {actionLog.length > 0 ?
                            <ul className="list-unstyled">
                                {actionLog.map((action, i) => <Link to={`/issuance/${action.link_id}`} className="no-link-decoration" key={i}><ActionEntry action={action} /></Link>)}
                            </ul> :
                            <div className="p-2 text-center text-muted">No notifications yet.</div>
                        }
                    </div>
                }
            </Dropdown>
        );
    }
}

export default Notifications;