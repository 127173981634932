const config = {
    // ApiRoot url
    apiHost: process.env.REACT_APP_API_HOST,
    // How often to refresh when a status is pending (in ms)
    REFRESH_INTERVAL: 60000,
    // How often to check for new notifications
    REFRESH_NOTIFICATIONS_INTERVAL: 60000,
    // For pagination of issuances
    ISSUANCES_PER_PAGE: 10,
    // For pagination of documents
    DOCUMENTS_PER_PAGE: 50,
    // URL of the public S3 bucket
    PUBLIC_BUCKET_URL: process.env.REACT_APP_PUBLIC_BUCKET_URL,
};

export default config;