import React from 'react'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


const getPaginationArray = (page, totalPages, pagesAround) => {
    let pages = []
    let added = 0
    let counter = page - pagesAround
    while (added < pagesAround * 2 + 1) {
        if (counter > totalPages) break
        if (counter > 0) {
            pages.push(counter)
            added++
        }
        counter++
    }
    // Go reverse if and fill up any remaining in case we're close to the last page
    if (added < pagesAround * 2 + 1) {
        let minAdded = pages[0]
        let remaining = minAdded - (pagesAround * 2 + 1 - added)
        for (let i = minAdded - 1; i >= remaining; i--) {
            if (i <= 0) break
            pages.unshift(i)
        }
    }
    if (pages[0] > 1) {
        if (pages[0] > 2) {
            pages.unshift('...')
        }
        pages.unshift(1)
    }
    if (pages[pages.length - 1] < totalPages) {
        if (pages[pages.length - 1] < totalPages - 1) {
            pages.push('...')
        }
        pages.push(totalPages)
    }

    return pages
}


const Pagination = ({ page, totalPages, pagesAround, getPageLink }) => (
    totalPages ?
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                <li className={classNames('page-item', { 'disabled': page === 1 })}>
                    <Link className="page-link" to={getPageLink(page - 1)} aria-label="Previous">
                        <span aria-hidden="true"><FontAwesomeIcon icon={faChevronLeft} /></span>
                    </Link>
                </li>
                {getPaginationArray(page, totalPages, pagesAround).map((p, i) =>
                    <li
                        className={classNames(
                            'page-item',
                            {
                                'active': page === p,
                                'disabled': page === '...',
                            }
                        )}
                        key={i}
                    >
                        <Link className="page-link" to={getPageLink(p)}>{p}</Link>
                    </li>
                )}
                <li className={classNames('page-item', { 'disabled': page === totalPages })}>
                    <Link className="page-link" to={getPageLink(page + 1)} aria-label="Next">
                        <span aria-hidden="true"><FontAwesomeIcon icon={faChevronRight} /></span>
                    </Link>
                </li>
            </ul>
        </nav> :
        null
)

export default Pagination;