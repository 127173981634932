import React from 'react';
import Error from './Error';

// Generic text input with label
// Default type is "text" but can be overriden
export const TextField = ({ label, name, type = 'text', ...rest }) => (
    <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input className="form-control" type={type} id={name} name={name} {...rest} />
        <Error errorObj={rest.errors} prop={name} />
    </div>
);

// Textarea with label
export const TextArea = ({ label, name, ...rest }) => (
    <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <textarea className="form-control" id={name} name={name} {...rest}></textarea>
        <Error errorObj={rest.errors} prop={name} />
    </div>
);

// Generic checkbox input with label
export const CheckboxField = ({ label, name, ...rest }) => (
    <div>
        <input type="checkbox" id={name} name={name} {...rest} />&nbsp;
        <label htmlFor={name}>{label}</label>
        <Error errorObj={rest.errors} prop={name} />
    </div>
);
