/* eslint-disable no-template-curly-in-string */
import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faInfoCircle, faHistory, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

// Using template curly syntax in normal strings here (because there's no context defined here)
// We're replacing them with the actual variables wherever we use them
export const STATUSES = {
    15: {
        name: 'PROCESSING',
        label: 'Processing',
        tooltip: 'This ${item} is being processed.<br/> This status will update automatically when it has been finalized',
        actionText: '${user} initiated an ${contentType} ${name}',
        colorClass: 'secondary',
        icon: faInfoCircle
    },
    20: {
        name: 'ISSUED_PENDING',
        label: 'Pending issuance',
        tooltip: 'This ${item} has not yet been confirmed on the blockchain.<br/> This status will update automatically when it has received confirmation',
        actionText: '${contentType} ${name} by user has been submitted to the blockchain, waiting for confirmation',
        colorClass: 'secondary',
        icon: faInfoCircle
    },
    25: {
        name: 'ISSUED',
        label: 'Issued',
        tooltip: 'This ${item} has been published successfully to the blockchain',
        actionText: '${contentType} ${name} by ${user} has been published to the blockchain',
        colorClass: 'success',
        icon: faCheckCircle
    },
    30: {
        name: 'EXPIRED',
        label: 'Expired',
        tooltip: 'This ${item} has expired, it is no longer valid',
        actionText: '${contentType} ${name} issued by ${user} has expired',
        colorClass: 'warning',
        icon: faHistory
    },
    40: {
        name: 'REVOKED_PENDING',
        label: 'Pending revocation',
        tooltip: 'This revocation has not yet been confirmed on the blockchain.<br/> This status will update automatically when it has been finalized',
        actionText: '${contentType} initiated the revocation of the ${item}: ${name}',
        colorClass: 'warning',
        icon: faBan
    },
    45: {
        name: 'REVOKED',
        label: 'Revoked',
        tooltip: 'This ${item} has been revoked successfully',
        actionText: '${contentType} ${name} issued by ${user} has been revoked from the blockchain',
        colorClass: 'danger',
        icon: faBan
    },
    100: {
        name: 'FAILED',
        label: 'Failed',
        tooltip: 'This ${item} has failed processing',
        actionText: '${contentType} ${name} issued by ${user} has failed processing. Please contact support',
        colorClass: 'danger',
        icon: faTimesCircle
    },
    150: {  // This status doesn't come from backend, only set in <Documents /> if document.cloud_deleted is true
        name: 'DELETED',
        label: 'Deleted',
        tooltip: 'This ${item} has been deleted from Block.co\'s storage',
        colorClass: 'warning',
        icon: faTimesCircle
    },
}

// Generate a map of { STATUS_NAME: STATUS_ID }
let tmpStatusMap = {};
for (let key in STATUSES) {
    tmpStatusMap[STATUSES[key].name] = key;
}
export const STATUS_NAME_TO_ID = tmpStatusMap;

export const isPending = (status) => STATUSES[status].name.includes('PENDING') || STATUSES[status].name.includes('PROCESSING');
export const isExpired = (status) => STATUSES[status].name === 'EXPIRED';
export const isRevoked = (status) => STATUSES[status].name.includes('REVOKED');
export const isFailed = (status) => STATUSES[status].name.includes('FAILED');

const Status = ({ status, id, item='issuance' }) => (
    <span id={"issuance_status_" + id}
        className={classNames('badge', `badge-${STATUSES[status].colorClass}`)}
        data-tip={STATUSES[status].tooltip.replace('${item}', item)}
        style={{ cursor: 'default' }}
    >
        <FontAwesomeIcon icon={STATUSES[status].icon} /> {STATUSES[status].label}
    </span>
);

export default Status;
