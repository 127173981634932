import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Loader = ({ text }) => (
    <div className="text-center my-5">
        <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
        {text && <div className="my-3">{text}</div>}
    </div>
)

export default Loader;