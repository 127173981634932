import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import config from '../config';
import request from '../api-request';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { GENERIC_ERROR_LOAD_MSG } from '../constants';
import Datetime from '../components/Datetime';
import Status, { isFailed, isPending } from '../components/Status';
import history from '../history';


class Issuances extends React.Component {
    refreshTimeout = null;
    state = {
        loading: true,
        errors: null,
        issuances: []
    };

    componentDidMount() {
        this.getIssuances();
    }

    componentWillUnmount() {
        this.clearRefresh();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page) {
            this.getIssuances();
        }
    }

    clearRefresh = () => {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    startRefresh = () => {
        this.clearRefresh();
        this.refreshTimeout = setTimeout(() => { this.getIssuances(false); }, config.REFRESH_INTERVAL);
    }

    getIssuances = (showLoading = true) => {
        // We don't want to show the loading during refreshes
        if (showLoading) {
            this.setState({ loading: true, errors: null });
        }

        const page = this.props.page || 1;

        request.get(`/issuance/?page=${page}`)
            .then(res => {
                const issuances = res.data.results;
                this.setState({ loading: false, issuances });
                if (this.props.paginate) {
                    this.props.paginate(res.data.total);
                }
                let shouldRefresh = issuances.some(issuance => isPending(issuance.status) || !issuance.uploaded_to_cloud);
                if (shouldRefresh) {
                    this.startRefresh();
                }
                setTimeout(() => ReactTooltip.rebuild());
            })
            .catch(error => {
                const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_LOAD_MSG };
                this.setState({ loading: false, errors: err })
            });
    }


    openRevokeModal = (issuance) => {
        this.setState({
            modalOpen: true,
            issuanceToRevoke: issuance
        });
    }

    closeRevokeModal = () => {
        this.setState({
            modalOpen: false,
            issuanceToRevoke: null
        });
    }

    viewIssuanceDetails = (issuanceId) => {
        history.push(`/issuance/${issuanceId}`);
    }

    updateIssuance = (issuanceId, prop, value) => {
        const { issuances } = this.state;
        for (const issuance of issuances) {
            if (issuance.id === issuanceId) {
                issuance[prop] = value;
                break;
            }
        }
        this.setState({ issuances });
    }

    render() {
        return (
            <>
                {this.state.loading ?
                    <Loader /> :
                    this.state.errors ?
                        <Error errorObj={this.state.errors} prop="detail" /> :
                        <>
                            {this.state.issuances.length === 0 && (
                                <p className="text-center text-muted">You haven't issued any documents yet. Click <Link to="/issue/">here</Link> to create an issuance</p>
                            )}
                            {this.state.issuances.length > 0 && (
                                <div className="table-responsive">
                                    <table className="table clickable-row-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Issued at</th>
                                                <th>Documents</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.issuances.map((issuance, i) => (
                                                <tr
                                                    key={i}
                                                    onClick={() => this.viewIssuanceDetails(issuance.id)}
                                                    className={classNames({ 'text-muted': issuance.cloud_deleted || isFailed(issuance.status) })}
                                                >
                                                    <td><Link name="issuance_link" to={`/issuance/${issuance.id}`}>{issuance.name}</Link></td>
                                                    <td><Datetime utc={issuance.issued_at || issuance.created} /></td>
                                                    <td>
                                                        {issuance.documents.total}
                                                        {issuance.documents.revoked > 0 && (
                                                            <> <span className="text-danger">({issuance.documents.revoked} revoked)</span></>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Status status={issuance.status} item="issuance" />
                                                        {issuance.cloud_deleted && (
                                                            <>
                                                                <br/>
                                                                <span
                                                                    className={classNames('badge', `badge-warning`)}
                                                                    data-tip="Documents in this issuance have been deleted from Block.co's storage"
                                                                    style={{ cursor: 'default' }}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashAlt} /> Documents deleted
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                }
            </>
        );
    }
}

export default Issuances;