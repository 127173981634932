import React from 'react';
import Modal from 'react-modal';


const AppModal = ({ isOpen, closeModal, title, body, controls, style }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel={(typeof title === 'string') ? title : ''}
        shouldCloseOnOverlayClick={false}
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        overlayClassName="modal"
        appElement={document.getElementById('root')}
    >
        <div className="modal-content" style={{ width: '600px', ...style }}>
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {body}
            </div>
            {controls && (
                <div className="modal-footer">
                    {controls}
                </div>
            )}
        </div>
    </Modal>
);

export default AppModal;