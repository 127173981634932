import React, { Suspense } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';

import Dashboard from './dashboard/Dashboard';
import IssuancesContainer from './issuances/IssuancesContainer';
import Logout from './auth/Logout';
import Loader from './components/Loader';
import Header from './Header';
import Footer from './Footer';
import { getUserData } from './auth/actions'

// Lazy loading components
const Issue = React.lazy(() => import('./issuances/issue/Issue'));
const Search = React.lazy(() => import('./issuances/Search'));
const IssuanceDetails = React.lazy(() => import('./issuances/issuance-details/IssuanceDetails'));
const Account = React.lazy(() => import('./auth/Account'));
const Help = React.lazy(() => import('./help/Help'));


class App extends React.Component {
    componentDidMount() {
        this.props.getUserData();
    }

    render() {
        return this.props.userData ?
            <>
                <Header />

                <main className="flex-fill my-4">
                    {this.props.location.pathname !== '/account/billing/' && this.props.userData.client.has_pending_order && (
                        <div className="container">
                            <div className="alert alert-danger">
                                You have a pending invoice. Go to the <Link to="/account/billing/">Billing</Link> page to finalize payment.
                            </div>
                        </div>
                    )}
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <Route exact path="/" component={Dashboard} />
                            <Route exact path="/search/:query" component={Search} />
                            <Route exact path="/issue/" component={Issue} />
                            <Route exact path="/issuances/:page?" component={IssuancesContainer} />
                            <Route exact path="/issuance/:id/:page?" component={IssuanceDetails} />
                            <Route exact path="/issuance/:id/document/:documentId" component={IssuanceDetails} />
                            <Route exact path="/logout/" component={Logout} />
                            <Route path="/account/" component={Account} />
                            <Route path="/help/" component={Help} />
                        </Switch>
                    </Suspense>
                </main>

                <Footer />
            </> :
            <Loader />;
    }
}

export default connect(
    state => ({
        userData: state.auth.user.userData,
    }),
    { getUserData }
)(App);
