import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


export default class Dropdown extends React.Component {
    state = {
        dropdownOpen: false
    };

    container = React.createRef();
    button = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('scroll', this.closeDropdown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('scroll', this.closeDropdown);
    }

    closeDropdown = () => {
        if (this.state.dropdownOpen) {
            this.setState({ dropdownOpen: false });
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.closeDropdown();
        }
    }

    toggleDropdown = (e) => {
        e.stopPropagation();
        const dropdownOpen = !this.state.dropdownOpen;
        if (dropdownOpen) {
            const buttonRect = this.button.current.getBoundingClientRect();
            const top = buttonRect.bottom;
            const side = this.props.position === 'left' ?
                buttonRect.left :
                document.body.clientWidth - buttonRect.right;
            const dropdownStyle = { top: `${top}px` };
            if (this.props.position === 'left') {
                dropdownStyle.left = `${side}px`;
            } else {
                dropdownStyle.right = `${side}px`
            }

            this.setState({ dropdownStyle });
        }
        this.setState({ dropdownOpen });

        if (dropdownOpen && this.props.onOpen) {
            this.props.onOpen();
        }
        if (!dropdownOpen && this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const { component: Component, className, buttonClassName, menuClassName, position='right', includeCaret=true, children } = this.props;
        return (
            <Component className={classNames(className, 'dropdown')} ref={this.container}>
                <button
                    className={classNames(buttonClassName, 'dropdown-toggle', { 'active': this.state.dropdownOpen })}
                    onClick={this.toggleDropdown}
                    type="button"
                    aria-haspopup="true"
                    aria-expanded={this.state.dropdownOpen ? 'true' : 'false'}
                    ref={this.button}
                >
                    <div className="d-flex">
                        {this.props.button}
                        {includeCaret && <FontAwesomeIcon icon={faCaretDown} className="align-self-center ml-2" />}
                    </div>
                </button>
                {this.state.dropdownOpen && (
                    <div
                        className={classNames('dropdown-menu', `dropdown-menu-${position}`, 'show', 'position-fixed', menuClassName)}
                        style={this.state.dropdownStyle}
                        aria-labelledby="dropdown-popup"
                        onClick={this.toggleDropdown}
                    >
                        {children}
                    </div>
                )}
            </Component>
        );
    }
}
