import React from 'react';
import { connect } from 'react-redux';

import Error from '../components/Error';
import { logout } from './actions';

class Logout extends React.Component {
    state = {
        loading: true,
        errors: null
    };

    handleErrors = (err) => {
        const errors = {
            non_field_errors: (err.response && err.response.body) ?
                [err.response.body.detail] :
                ['Something went wrong, please try again']
        };
        this.setState({ loading: false, errors });
    }

    componentDidMount() {
        this.props.logout();
    }

    render() {
        if (this.state.loading) {
            return <h2>Logging out, please wait...</h2>
        }

        return (
            <>
                <h2>Log out failed...</h2>
                <Error errorObj={this.state.errors} prop="detail" />
            </>
        );
    }
}

export default connect(
    null,
    { logout }
)(Logout);