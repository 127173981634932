import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

// Renders either an array of errors, or a single error
const Error = ({ errorObj, prop, box=false }) => {
    if (errorObj && errorObj[prop]) {
        if (Array.isArray(errorObj[prop])) {
            return errorObj[prop].map((err, i) => (
                <p className={classNames({ 'text-danger': !box, 'alert alert-danger': box })} key={i}><FontAwesomeIcon icon={faExclamationCircle} /> {err}</p>
            ))
        } else {
            return <p className={classNames({ 'text-danger': !box, 'alert alert-danger': box })}><FontAwesomeIcon icon={faExclamationCircle} /> {errorObj[prop]}</p>
        }
    }

    return null
};

export default Error;