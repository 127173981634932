import React from 'react';
import { Link } from "react-router-dom";

import Helmet from '../helmet';
import logo from '../img/logo_dark_wide.png';


const Privacy = () => (
    <div className="container my-5">
        <Helmet>
            <title>Privacy policy</title>
        </Helmet>
        <Link to="/">
            <img style={{ display: 'block', width: '200px', margin: '0 auto', marginBottom: '1rem' }} src={logo} alt="Block.co"/>
        </Link>
        <div className="card">
            <div className="card-body">
                <h1 className="my-heading text-center" style={{ marginBottom: '1rem' }}>
                    Privacy Policy
                </h1>
                <div>
                    <p>This privacy policy (“Policy”) describes how Block.co (“Company”, “we”, “our”, or “us”) collects, uses, shares and stores personal data. This Policy applies to the site, applications, products and services (collectively, “Services”).</p>
                    <h4>A. Data Processing</h4>
                    <h5 style={{ textDecoration: 'underline' }}>Type of personal data held by Block.co:</h5>
                    <p>We hold information relating to you from different sources. Nevertheless, the information we hold come essentially from the information you personally provide us or that was transferred to us from a Controller pursuant to the terms of a Data Processing Agreement and the services we provide.</p>
                    <h5>1.Data stored on the Blockchain</h5>
                    <p>We may store your Personal Data with appropriate physical, technological and organizational safeguards and security measures on the Blockchain. As a part of our service we enable the issuance of digital certificates anchored to the Blockchain assuring immutability, and security, instead of the storage of certificates on a cloud, computer machine or hardcopy.</p>
                    <p>Once issued and successfully anchored on the Blockchain, a certificate has no ongoing dependence on Block.co. These records are anchored on the blockchain that recipients own for a lifetime.</p>
                    <p>We have also developed a methodology whereby certificates can be revoked in case there is a need for that.</p>
                    <h5>2. Accessing this website and creation of log files</h5>
                    <p>Information are collected every time this website is accessed or used. These data and information are stored in log files on the server and can include:</p>
                    <p>The temporary storage of data and log files is lawful pursuant to Art. 6 (1) General Data Protection Regulation. The IP address is temporarily stored in the system as it is necessary to provide website access to the User’s computer. The IP address is retained while that website is being accessed. These log files are stored to ensure website functionality, optimize the content of our website, and ensure the security of our IT system.</p>
                    <p>The data will be deleted when they are no longer needed for the purpose they were collected. For data collected to provide access to the website, this will be at the end of every session.</p>
                    <p>For log files, this will occur after seven days at the latest. Some data may be preserved for a longer period of time, in which case user IP addresses are deleted or removed, rendering it impossible to link the data to any individual.</p>
                    <h5>3. Cookies</h5>
                    <p>Our website uses cookies. Cookies are small data files, created and stored by the Internet browser on your computer’s hard drive. Accessing a website may result in a cookie being saved on your operating system. This cookie contains a specific string of characters that allows the browser to be clearly recognized every time the website is accessed.</p>
                    <p>We use cookies to make our website more user-friendly.</p>
                    <p>The processing of personal data based on the use of cookies is lawful pursuant to Art. 6 (1) GDPR.</p>
                    <p>The purpose of these technical cookies is to simplify website use.</p>
                    <p>Cookies are stored on the User’s computer and transferred to us. That is why you, as the User, have full control over cookie implementation. You can deactivate or restrict cookies by changing your browser settings. Cookies already stored on your hard drive can be deleted at any time. This can also be done automatically. However, disabling cookies for our website may result in some functions not working correctly.</p>
                    <h5>4. Contact form and email contact</h5>
                    <p>There are contact forms on Block.co webpages that can be used to communicate electronically. When registering, the data entered by you into the online data entry form will be transmitted. Your consent is required for the processing of this data, and you will be referred to our Policy and asked to grant your consent when you send the form.</p>
                    <p>Alternatively, contact may be initiated using an email address provided by you. In this case, the personal data provided in the email will be stored. This information will not be passed on to third parties.</p>
                    <p>The processing of information received from the sending of an email is lawful under Art. 6 (1) (b) GDPR.</p>
                    <p>These data are only stored for the purposes of processing that communication. The data will be deleted when they are no longer needed for the purpose they were collected.</p>
                    <h5 style={{ textDecoration: 'underline' }}>Your Reinforced rights:</h5>
                    <p>Block.co has taken appropriate measures to provide any information relating to your rights as well as the exercise of these rights. Under Chapter III of the GDPR you have the following rights:</p>
                    <h5>1. Transparent information, communication and modalities for the exercise of your rights</h5>
                    <p>You have the right to be provided with your data freely and in an intelligible and easily accessible form. Following your request, Block.co shall provide information without undue delay and in any event within one month of receipt of the request. That period may be extended by two further months where necessary, taking into account the complexity and number of requests. In that case the Block.co will inform you for the reasons of the delay.</p>
                    <h5>2. Information to be provided where personal data are collected from the data subject</h5>
                    <p>You have the right to know the contact details of the agent who collects your data, the purposes of the processing for which the personal data are intended, the legal basis for the processing, the recipients or categories of recipients of the personal data and where applicable the fact that the controller intends to transfer personal data to a third country.</p>
                    <h5>3. Right of access</h5>
                    <p>You have the right to request and receive a copy of your personal data undergoing processing. However, for any further copies requested, Block.co may charge you a reasonable fee that is based on administrative costs.</p>
                    <h5>4. Right to rectification</h5>
                    <p>You have the right to obtain from Block.co within reasonable time the rectification of inaccurate personal data concerning you.</p>
                    <h5>5. Right to erasure (‘right to be forgotten’)</h5>
                    <p>There is a right to ask for the erasure of your personal data and no longer processed where the personal data are no longer necessary in relation to the purposes for which they are controlled or otherwise processed.</p>
                    <p>Hence, in the cases where Block.co retains and process personal data in accordance with the provisions of Article 6(1)(c) of the GDPR, Block.co may object to such a request and may keep the relevant personal data that are required in order for Block.co to comply with its legal obligations or the legal obligations of a Controller that cooperates with Block.co pursuant to the terms of a Data Processing Agreement.</p>
                    <h5>6. Right to restriction of processing</h5>
                    <p>We ensure that the Block.co has in place a procedure where you have the right to restrict the processing of your personal data. Nonetheless, for those personal data that are necessary for compliance with a legal obligation, Block.co may object to the restriction.</p>
                    <h5>7. Right to data portability</h5>
                    <p>You have the right to receive the personal data concerning you, which you have provided to Block.co, in a structured, commonly used and machine-readable format and you have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided.</p>
                    <h5>8. Right to object</h5>
                    <p>You have the right to object to the processing of your personal data. However, since we lawfully processes such data under Article 6(1)(c), we will still have the right to process the data.</p>
                    <h5>9. Automated individual decision-making, including profiling</h5>
                    <p>You have the right no to be subject to a decision solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects.</p>
                </div>
            </div>
        </div>
    </div>
);

export default Privacy;