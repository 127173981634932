import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import WithLabel from '../components/WithLabel';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { getStats } from '../auth/actions'


class Stats extends React.Component {
    componentDidMount() {
        this.props.getStats()
    }

    render() {
        const { userData, stats } = this.props;

        return (
            <div className="card p-4">
                <h2 className="my-heading text-center">Remaining credits</h2>
                {stats.loading ?
                    <Loader /> :
                    <>
                        {userData && userData.client && (
                            <WithLabel className="text-center" label="Organization" value={userData.client.name} />
                        )}
                        <Error errorObj={stats.errors} prop="detail" />
                        {stats.totals && (
                            <>
                                {!this.props.userData.client.options.only_see_document_credits && <WithLabel className="text-center" label="Remaining transactions" value={stats.totals.transactions} />}
                                {!this.props.userData.client.options.only_see_tx_credits       && <WithLabel className="text-center" label="Remaining documents" value={stats.totals.certificates} /> }
                            </>
                        )}
                    </>
                }
                <div className="container-footer">
                    <Link id="manage" to="/account/billing/">Manage</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        userData: state.auth.user.userData,
        stats: state.auth.stats
    }),
    { getStats }
)(Stats);
