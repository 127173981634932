import axios from 'axios';
import config from './config';
import { tokenExpired } from './auth/actions';
import store from './store/store';


const request = axios.create({
    baseURL: `${config.apiHost}/`
});

request.interceptors.request.use(
    config => {
        // Add the token in Authorization header if there's one saved
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            config.headers['Authorization'] = `Token ${authToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

request.interceptors.response.use(
    response => response,
    error => {
        // If we get a 401 response, clear user data and token, and redirect to login
        // Probably the token expired, or was deleted server-side
        if (error.response && error.response.status === 401) {
            store.dispatch(tokenExpired());
        }

        return Promise.reject(error);
    }
);

export default request;
