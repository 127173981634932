import React from 'react';
import { Link } from 'react-router-dom';

import Helmet from '../helmet';
import Stats from './Stats';
import Issuances from '../issuances/Issuances';
import { connect } from 'react-redux';


const Dashboard = ({ userData }) => {
    return (
    
    <div className="container">
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
        <div className="row">
            <div className="col-lg-9 col-12">
                <div className="card p-4 mb-4">
                    <h2 className="my-heading text-center">Latest issuances</h2>
                    <Issuances />
                    {userData.client.has_pk_ownership !== true && 
                        <div className="container-footer">
                            <Link id="new_issuance" to="/issue/">New issuance</Link>
                        </div>
                    }
                   
                </div>
            </div>
            <div className="col-lg-3 col-12">
                <Stats />
            </div>
        </div>
    </div>
)}

export default connect(
    state => ({
        userData: state.auth.user.userData,
    })
)(Dashboard);
