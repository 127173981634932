import React from 'react';
import './scss/Footer.scss';

const Footer = () => {
	return (
		<footer className="bg-dark">
			<div className="text-light text-center py-2">
				© 2021 - Block.co
			</div>
		</footer>
	);
}


export default Footer;