import React from 'react';

import Helmet from '../helmet';
import config from '../config';
import Pagination from '../components/Pagination';
import Issuances from './Issuances';


export default class IssuancesContainer extends React.Component {
    state = {
        page: 1
    }

    parsePage = () => {
        let page;
        try {
            page = parseInt(this.props.match.params.page) || 1;
        } catch (e) {
            page = 1;
        }

        return page;
    }

    componentDidUpdate() {
        const page = this.parsePage()

        if (this.state.page !== page) {
            this.setState({ page });
        }
    }

    paginate = (total) => {
        const totalPages = Math.ceil(total / config.ISSUANCES_PER_PAGE);
        this.setState({ totalPages });
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Issuances</title>
                </Helmet>
                <div className="row">
                    <div className="col">
                        <div className="card p-4">
                            <h2 className="my-heading text-center">All issuances</h2>
                            <Issuances page={this.state.page} paginate={this.paginate} />
                            {this.state.totalPages > 0 && (
                                <Pagination
                                    page={this.state.page}
                                    totalPages={this.state.totalPages}
                                    pagesAround="5"
                                    getPageLink={(page) => `/issuances/${page}`}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
