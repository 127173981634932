import { createAction } from 'redux-starter-kit';
import request from '../api-request';
import history from '../history';
import { GENERIC_ERROR_MSG, GENERIC_ERROR_LOAD_MSG } from '../constants';


// Simple actions using `createAction` from `redux-starter-kit`
export const setUserData = createAction('auth/SET_USER_DATA');
export const setClientLogo = createAction('auth/SET_CLIENT_LOGO');
export const setDomainIdentificationStarted = createAction('auth/SET_DOMAIN_IDENTIFICATION_STARTED');
export const setDomainIdentificationFailed = createAction('auth/SET_DOMAIN_IDENTIFICATION_FAILED');
export const setDomainIdentification = createAction('auth/SET_DOMAIN_IDENTIFICATION');
export const clearUserData = createAction('auth/CLEAR_USER_DATA');
export const setAuthToken = createAction('auth/login/SET_AUTH_TOKEN');
export const statsStarted = createAction('auth/stats/STATS_STARTED');
export const statsFailed = createAction('auth/stats/STATS_FAILED');
export const setStats = createAction('auth/stats/SET_STATS');


// Redux thunk actions (async, or with side-effects)

export const tokenExpired = () => dispatch => {
    localStorage.setItem('authToken', '');
    dispatch(clearUserData());
    history.push('/login/');
};

export const logout = () => dispatch => {
    request.post('/auth/logout/')
        .then(res => {
            localStorage.setItem('authToken', '');
            dispatch(clearUserData());
            history.push('/login/');
        });
};

export const getUserData = () => dispatch => {
    request.get('auth/user-data/')
        .then(res => {
            dispatch(setUserData(res.data));
        });
};

export const toggleDomainIdentityProof = () => (dispatch, getState) => {
    const domain_identity_proof = !getState().auth.user.userData.client.domain_identity_proof;

    dispatch(setDomainIdentificationStarted());
    request.put('/auth/client/', { domain_identity_proof })
        .then(res => {
            dispatch(setDomainIdentification(domain_identity_proof))
        })
        .catch(error => {
            const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_MSG };
            dispatch(setDomainIdentificationFailed(err))
        })

};

export const getStats = () => dispatch => {
    dispatch(statsStarted());
    request.get('/auth/order/')
        .then(res => {
            let totals = {
                transactions: 0,
                certificates: 0
            };
            for (const order of res.data.active) {
                totals.transactions += order.remaining_transactions;
                totals.certificates += order.remaining_certificates;
            }

            dispatch(setStats(totals));
        })
        .catch(error => {
            const err = (error.response && error.response.data) ? error.response.data : { detail: GENERIC_ERROR_LOAD_MSG };
            dispatch(statsFailed(err));
        })
};
