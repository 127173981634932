/* eslint-disable default-case */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'

import { STATUSES } from '../components/Status';
import Datetime from './Datetime';


const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const User = connect(
    state => ({
        userData: state.auth.user.userData,
    })
)(({ userData, username, capitalize = false }) => {
    if (userData && userData.username === username) {
        return capitalize ? 'You' : 'you';
    } else {
        return <><FontAwesomeIcon icon={faUser} />{username}</>
    }
});

const Entry = ({ action, text, date, unread }) => (
    <li className={classNames('d-flex action-log-item p-2', { unread })}>
        <div className="icon-container">
            <FontAwesomeIcon icon={action.icon} size="lg" className={`text-${action.colorClass}`} />
        </div>
        <div className="pl-3">
            <div className="mb-1">{text}</div>
            <div className="text-muted"><Datetime utc={date} singleRow={true} /></div>
        </div>
    </li>
);

const ActionEntry = ({ action }) => {
    const { action_type, user, created, content_type, name } = action;
    const actionDisplay = STATUSES[action_type];
    let res = null;
    switch (STATUSES[action_type].name) {
        case 'ISSUED_PENDING':
            res = <Entry action={actionDisplay} date={created} unread={action.unread} text={<><User username={user} capitalize={true} /> initiated an issuance: <span className="like-link">{name}</span></>} />;
            break;
        case 'ISSUED':
            res = <Entry action={actionDisplay} date={created} unread={action.unread} text={<>Issuance <span className="like-link">{name}</span> (issued by <User username={user} />) was published on the blockchain</>} />;
            break;
        case 'EXPIRED':
            res = <Entry action={actionDisplay} date={created} unread={action.unread} text={<>Issuance <span className="like-link">{name}</span> (issued by <User username={user} />) has expired</>} />;
            break;
        case 'REVOKED_PENDING':
            res = <Entry action={actionDisplay} date={created} unread={action.unread} text={<><User username={user} capitalize={true} /> initiated a revocation of {content_type} <span className="like-link">{name}</span></>} />;
            break;
        case 'REVOKED':
            res = <Entry action={actionDisplay} date={created} unread={action.unread} text={<>{capitalizeFirst(content_type)} <span className="like-link">{name}</span> (issued by <User username={user} />) has been revoked on the blockchain</>} />;
            break;
    }

    return res;
}

export default ActionEntry;