import React from 'react';
import classNames from 'classnames'
import '../scss/WithLabel.scss'


const WithLabel = ({ label, value, ...rest }) => (
    <div className={classNames('with-label', rest.className)}>
        <label htmlFor={label + value}>{label}</label>
        <div>{value}</div>
    </div>
)

export default WithLabel;