import React from 'react';
import ReactCodeInput from "react-code-input";
import Error from '../components/Error';


const NUM_DIGITS = 6;


export default function OTPForm({ loading, error, setOTP }) {
    function codeChange(c) {
        setOTP(c);
    }

    return (
        <div className="text-center">
            <Error errorObj={error} prop="detail" />
            <label className="d-block">Enter your 2FA code:</label>
            <ReactCodeInput
                type='text'
                fields={NUM_DIGITS}
                onChange={codeChange}
                disabled={loading}
                pattern={'/^[0-9]$/'}
            />
        </div>
    )
}
