import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Helmet from '../helmet';
import logo from '../img/logo_dark_wide.png';


const EmailVerificationSuccess = () => (
    <div className="container my-5">
        <Helmet>
            <title>Privacy policy</title>
        </Helmet>
        <Link to="/">
            <img style={{ display: 'block', width: '200px', margin: '0 auto', marginBottom: '1rem' }} src={logo} alt="Block.co" />
        </Link>
        <div className="card">
            <div className="card-body text-center">
                <h1 className="my-heading">
                    Your email address has been verified successfully.
                </h1>
                <h1 className="my-5">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                </h1>
                <p>You may now send documents to their recipients using your email address.</p>
                <p>
                    <Link to="/" className="btn btn-primary">
                        Go to Dashboard
                    </Link>
                </p>
            </div>
        </div>
    </div>
);


const EmailVerificationFail = () => (
    <div className="container my-5">
        <Helmet>
            <title>Privacy policy</title>
        </Helmet>
        <Link to="/">
            <img style={{ display: 'block', width: '200px', margin: '0 auto', marginBottom: '1rem' }} src={logo} alt="Block.co" />
        </Link>
        <div className="card">
            <div className="card-body text-center">
                <h1 className="my-heading">
                    Your email address was not verified successfully.
                </h1>
                <h1 className="my-5">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
                </h1>
                <p>Please contact <a href="mailto:enquiries@block.co" target="_blank" rel="noopener noreferrer">support</a> to resolve this issue.</p>
                <p>
                    <Link to="/" className="btn btn-primary">
                        Go to Dashboard
                    </Link>
                </p>
            </div>
        </div>
    </div>
);

export { EmailVerificationSuccess, EmailVerificationFail };